<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-button @click="enableNotif">Enable Notif</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
navigator.serviceWorker.register("sw.js");

export default {
  name: "Push",
  methods: {
    enableNotif: function() {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          // get service worker
          navigator.serviceWorker.ready.then(sw => {
            // subscribe
            sw.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey:
                  "BKrWekYcIq3GL8yQZCfS7A30OcHu_aOMEiN5PLv3GBrbfeFEG1Mwd1WnivSkql0s_fUQ_XBcET8GosU56WLNP8Y"
              })
              .then(subscription => {
                console.log(JSON.stringify(subscription));
              });
          });
        }
      });
    }
  }
};
</script>
